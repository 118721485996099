import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/delivery.module.scss'
import DeliveryProductPresentation from '../pagesSections/delivery/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import CRMFeatures from '../pagesSections/delivery/CRMFeatures'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import MobileFeatures from '../pagesSections/delivery/MobileFeatures'
import { Integrations } from '../pagesSections/delivery/Integrations'
import Presentation from '../pagesSections/delivery/ProductPresentation/assets/delivery-presentation.png'
import PresentationBy from '../pagesSections/delivery/ProductPresentation/assets/delivery-presentation_by.png'
import PresentationKz from '../pagesSections/delivery/ProductPresentation/assets/delivery-presentation_kz.png'

import { getAdvantages } from '../pages-data/_V2/delivery/advantages'
import { externalLinks } from '../pages-data/_V2/common/links'

import DeliveryFeatures from '../pagesSections/delivery/DeliveryFeatures'
import DeliveryList from '../pagesSections/delivery/DeliveryList'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function DeliveryPage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Автоматизация доставки еды — интеграция бизнеса со службами доставки | Quick Resto',
    'ru-KZ': 'Автоматизация доставки еды — автоматизировать управление | Quick Resto Казахстан',
    'kz-KZ': 'Автоматизация доставки еды — автоматизировать управление | Quick Resto Казахстан',
    'ru-BY': 'Автоматизация доставки еды — автоматизировать управление | Quick Resto Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}delivery/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}delivery/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}delivery/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}delivery/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Автоматизация доставки еды — интеграция бизнеса со службами доставки | Quick Resto',
    'ru-KZ': 'Программа автоматизации доставки еды ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания для Зала и Доставки →Внедрение под ключ. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Программа автоматизации доставки еды ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания для Зала и Доставки →Внедрение под ключ. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Программа автоматизации доставки еды ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания для Зала и Доставки →Внедрение под ключ. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
    default: `${externalLinks.quickrestoRU.href}${Presentation}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    // {
    //   name: 'keywords',
    //   content: 'программа, лояльность, гости, мобильное приложение, предзаказ, пуш-уведомление, автоматизация, кафе, ресторан',
    // },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale] || ogImage.default,
    },
  ]

  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <DeliveryProductPresentation />

      <Advantages
        items={getAdvantages(localizationContext.locale)}
        className={classNames(
				  styles.delivery__advantages,
				  pageStyles.pageSection,
        )}
      />

      <CRMFeatures className={pageStyles.pageSection} />

      <DeliveryFeatures className={pageStyles.pageSection} />

      <MobileFeatures className={pageStyles.pageSection} />

      <DeliveryList className={pageStyles.pageSection} />

      {(localizationContext.locale === 'ru-KZ' || localizationContext.locale === 'kz-KZ') && (
        <Integrations className={pageStyles.pageSection} />
      )}

      <AdditionalServices />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessTypes(localizationContext.locale)}
        className={pageStyles.pageSection}
      />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
