import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links";
import styles from "./styles.module.scss";
import mediaContent from "./mediaContent.module.scss";
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Модуль доставки",
			desktopTitle: "Доставка \nпо своим правилам",
			subTitle: (
				<>
					<p className={classNames(mediaContent.smallDescription, mediaContent.media__desktop)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся
						к&nbsp;агрегаторам для увеличения клиентской базы.
					</p>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__tablet)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся к&nbsp;агрегаторам для увеличения клиентской базы.
					</noindex>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__mobile)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся к&nbsp;агрегаторам для увеличения клиентской базы.
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/delivery-presentation_kz.png"
					alt=""
					className={classNames(styles.image, styles.image__kz)}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Жеткізу модулі",
			desktopTitle: "Өз ережелері\nбойынша жеткізу",
			subTitle: (
				<>
					<p className={classNames(mediaContent.smallDescription, mediaContent.media__desktop)}>
						Бизнесті масштабтауға арналған дайын шешім. Өз сайтыңыз немесе қосымша арқылы
						тапсырыстарды алыңыз. Клиенттер қорын ұлғайту үшін агрегаторларға қосылыңыз.
					</p>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__tablet)}>
						Бизнесті масштабтауға арналған дайын шешім. Өз сайтыңыз немесе қосымша арқылы
						тапсырыстарды алыңыз. Клиенттер қорын ұлғайту үшін агрегаторларға қосылыңыз.
					</noindex>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__mobile)}>
						Бизнесті масштабтауға арналған дайын шешім. Өз сайтыңыз немесе қосымша арқылы
						тапсырыстарды алыңыз. Клиенттер қорын ұлғайту үшін агрегаторларға қосылыңыз.
					</noindex>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/delivery-presentation_kz.png"
					alt=""
					className={classNames(styles.image, styles.image__kz)}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Модуль доставки",
			desktopTitle: "Доставка \nпо своим правилам",
			subTitle: (
				<>
					<p className={classNames(mediaContent.smallDescription, mediaContent.media__desktop)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся к&nbsp;агрегаторам для увеличения клиентской базы.
					</p>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__tablet)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся к&nbsp;агрегаторам для увеличения клиентской базы.
					</noindex>
					<noindex className={classNames(mediaContent.smallDescription, mediaContent.media__mobile)}>
						Готовое решение для масштабирования бизнеса. Получай заказы через собственный сайт или приложение.
						Подключайся к&nbsp;агрегаторам для увеличения клиентской базы.
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/delivery-presentation_by.png"
					alt=""
					className={classNames(styles.image, styles.image__by)}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	return {
		pageName: "Модуль доставки",
		desktopTitle: "Доставка по своим правилам",
		subTitle: (
			<p className={mediaContent.smallDescription}>
				Готовое решение для автоматизации доставки из&nbsp;ресторана позволит масштабировать бизнес.
				Привлекай заказы через собственный сайт заведения или приложение. Подключайся к&nbsp;агрегаторам
				Яндекс Еда и&nbsp;Delivery Club и&nbsp;расширяй базу клиентов.
			</p>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				src="./assets/delivery-presentation_ru.png"
				alt=""
				className={styles.image}
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	};
};
